<script>
import StoryGroup from '@rei/story-group';
import LandingImageComponent from '@rei/landing-image-component';
import LandingImageComponentDeprecated from '@rei/landing-image-component-deprecated';
import { LandingContentBlock } from '@rei/landing-content-block';
import supportDeprecatedImageComponent from '../../mixins/supportDeprecatedImageComponent';
import palettes from '../../mixins/palettes';

export default {
  name: 'StoryCardPlacement',
  components: {
    StoryGroup,
    LandingImageComponent,
    LandingImageComponentDeprecated,
    LandingContentBlock,
  },
  mixins: [palettes, supportDeprecatedImageComponent],
  props: {
    media: { type: Object, required: false, default: () => {} },
    content: { type: Object, required: true, default: () => {} },
  },
};
</script>
<template>
  <div
    class="story-card-placement"
    :style="cssSurfacePaletteVars"
  >
    <story-group>
      <template #column-1>
        <component
          :is="imageComponent"
          :media="media"
          v-bind="media"
        />
      </template>
      <template #column-2>
        <div>
          <landing-content-block
            v-bind="content"
          />
        </div>
      </template>
    </story-group>
  </div>
</template>
<style lang="scss">
@import '@rei/cdr-tokens/dist/rei-dot-com/scss/cdr-tokens.scss';

.story-card-placement {
  border-radius: $cdr-radius-softer;
  padding: $cdr-space-two-x $cdr-space-three-quarter-x;
  background: var(--palette-background-color);

  @include cdr-sm-mq-up {
    padding: $cdr-space-three-x;
  }

  .story-group__grid {
    max-width: 960px;
    margin: auto;
  }

  .story-group__column:first-child {
    @include cdr-sm-mq-up {
      justify-content: end;
      align-items: center;
      padding: 0 3.2rem 0 0;
    }

    display: flex;
    justify-content: center;
  }
}
</style>
