<script>
import AmbientVideoPlayer from '@rei/ambient-video-player';
import BannerComponent from '@rei/banner-component';
import { LandingContentBlock } from '@rei/landing-content-block';
import LandingImageComponent from '@rei/landing-image-component';
import LeadCards from '../lead-cards/LeadCards.vue';
import palettes from '../../../../common/mixins/palettes';

export default {
  name: 'HomepageLead',
  components: {
    AmbientVideoPlayer,
    LandingImageComponent,
    BannerComponent,
    LandingContentBlock,
    LeadCards,
  },
  mixins: [palettes],
  props: {
    media: { type: Object, required: false, default: () => null },
    video: { type: Object, required: false, default: () => null },
    content: { type: Object, required: false, default: () => {} },
    bannerComponentProps: { type: Object, required: false, default: () => {} },
    videoFocalPoint: { type: String, required: false, default: '' },
    hasCards: { type: Boolean, required: false, default: false },
    cards: { type: Object, required: false, default: () => {} },
    leadAnalytics: { type: String, required: false, default: '' },
    leadCardAnalytics: { type: String, required: false, default: '' },
  },
  computed: {
    cssVars() {
      return {
        '--video-focal-point': this.videoFocalPoint,
        ...this.cssSurfacePaletteVars,
      };
    },
    useVideoBackground() {
      return !!this.video?.src;
    },
  },
};

</script>
<template>
  <div
    :class="['homepage-lead', {
      'homepage-lead--has-cards': hasCards,
    }]"
    :style="cssVars"
  >
    <banner-component
      class="lead-component"
      v-bind="bannerComponentProps"
      :data-location-name="leadAnalytics"
      :style="cssSurfacePaletteVars"
    >
      <template
        v-if="media"
        #background
      >
        <ambient-video-player
          v-if="useVideoBackground"
          v-bind="video"
          data-ui="lead-component-ambient-video-player"
        />
        <landing-image-component
          v-else
          :media="media"
          v-bind="media"
          :lazy-load="false"
          fetch-priority="high"
          data-ui="lead-component-image"
        />
      </template>
      <template
        v-if="content"
        #content
      >
        <landing-content-block
          data-ui="lead-component-content"
          v-bind="content"
        />
      </template>
    </banner-component>
    <lead-cards
      v-if="hasCards"
      :lead-card-analytics="leadCardAnalytics"
      :palette="palette"
      :cards="cards"
      data-ui="lead-component-cards"
    />
  </div>
</template>
<style lang="scss">
@import '@rei/cdr-tokens/dist/rei-dot-com/scss/cdr-tokens.scss';
@import "@rei/cdr-component-variables/dist/scss/index.scss";
.homepage-lead {
  background: var(--palette-background-color);

  // Handle special behavior if lead cards are there
  &--has-cards {
    // If cards, bump content block up the same amount as the card overlap.
    .ls-content-block {
      margin-bottom: $cdr-space-two-x;

      @include cdr-sm-mq-up {
        margin-bottom: $cdr-space-four-x;
      }
    }

    // If cards, ambient video controls need to be bumped up too.
    .ambient-video__controls {
      bottom: 7.4rem;
    }
  }

  .banner__content-block {
    z-index: 10;
  }

  .button-inline {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    background-color: transparent;
    border: none;
    padding: 0 0 .2rem 0;
    cursor: pointer;
    text-decoration: underline;

    @include cdr-sm-mq-down {
      padding: 0;
    }

    &:hover,
    &:focus,
    &:active,
    &:visited {
      color: $cdr-color-text-inverse;
      text-decoration: none;
    }
  }
  .banner__content-block {
    z-index: 0;
  }
  .ambient-video {
    z-index: 0;

    &.ambient-video--cover.ambient-video--ready {
      position: static;
    }
    &__player {
      object-position: var(--video-focal-point);
    }
    &__controls {
      z-index: 10;
    }
  }
}
</style>
