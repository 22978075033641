<script>
import headingSection from '../../../pages/home/components/headingSection/headingSection.vue';
import InfoCard from './InfoCard.vue';

export default {
  name: 'InfoCards',
  components: {
    InfoCard,
    headingSection,
  },
  props: {
    heading: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    cards: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
};
</script>
<template>
  <div class="info-cards">
    <heading-section
      v-if="heading"
      :heading="heading.heading.text"
    />
    <div class="info-cards__rail">
      <info-card
        v-for="(card, index) in cards"
        v-bind="card"
        :key="`info-card-${index}`"
      />
    </div>
  </div>
</template>
<style lang="scss">
@import '@rei/cdr-tokens/dist/rei-dot-com/scss/cdr-tokens.scss';

.info-cards {
  &__rail {
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    flex-wrap: nowrap;
    padding-bottom: 16px;
    gap: 16px;

    @include cdr-lg-mq-down {
      overflow: scroll;
      overflow-y: hidden;
    }
  }
}
</style>
