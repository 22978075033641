import { onLCP } from 'web-vitals';

function triggerMetricsEvent(metricLCP) {
  if (window.metrics && typeof window.metrics.event === 'function') {
    window.metrics.event({
      LCP: metricLCP.value,
    });
  }
}

export default {
  mounted() {
    // add this callback when the vue app mounts
    // callback fires when document.hidden transitions to true
    onLCP(triggerMetricsEvent);
  },
};
