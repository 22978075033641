/* v8 ignore start */
<script>
import LandingImageComponent from '@rei/landing-image-component';
import { LandingContentBlock } from '@rei/landing-content-block';
import { palettes } from '@rei/landing-palettes';

export default {
  name: 'LeadRailCard',
  components: {
    LandingImageComponent,
    LandingContentBlock,
  },
  props: {
    media: { type: Object, required: false, default: () => null },
    content: { type: Object, required: false, default: () => {} },
  },
  data() {
    return {
      palettes: palettes(this.content?.config.palette),
    };
  },
  computed: {
    cssVars() {
      return {
        ...this.palettes.cssSurfacePaletteVars(),
      };
    },
  },
  methods: {
    btnClick(event) {
      if (!event.target.href) return;
      const { index } = this.$refs.card.dataset;
      const linkText = event.target.textContent.trim();
      window.metrics?.link({
        linkName: `ct_1_lead_${+index + 1}_${linkText}`,
      }, event.target.href);
    },
  },
};
</script>
<template>
  <div
    ref="card"
    class="lead-rail-card"
    :style="cssVars"
  >
    <div class="lead-rail-card__hero">
      <landing-image-component
        v-if="media"
        v-bind="media"
        :lazy-load="false"
        fetch-priority="high"
        data-ui="lead-component-image"
      />
    </div>
    <div
      class="lead-rail-card__content"
    >
      <landing-content-block
        v-if="content"
        data-ui="lead-component-content"
        v-bind="content"
        @click.capture.stop="btnClick"
      />
      <p v-else>Some test text goes here, yeah?</p>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import '@rei/cdr-tokens/dist/rei-dot-com/scss/cdr-tokens.scss';

.lead-rail-card {
  display: grid;
  background: var(--palette-background-color);
  scroll-snap-align: center;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr;
  max-width: 315px;
  min-width: 315px;
  flex-shrink: 0 !important;
  flex-basis: 100%;
  align-items: start;
  margin: 0 4px;

  @include cdr-sm-mq-up {
    margin: 0 8px;
    max-width: 619px;
  }

  &[data-is-first=true] {
    margin-left: 0;
  }

  &[data-is-last=true] {
    margin-right: 0;
  }

  /**
  * NOTE: if the aspect ratio here changes,
  * change it for the '.control-height' div in the rail too!
  */
  &__hero {
    position: relative;
    width: 100%;
    height: auto;
    vertical-align: middle;
    overflow: hidden;
    aspect-ratio: 315/226;

    :deep(.landing-image-component) {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    :deep(.landing-image-component__image) {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    @include cdr-sm-mq-up {
      aspect-ratio: 619/443;
    }
  }

  &__content {
    width: 100%;
    padding: $cdr-space-one-x $cdr-space-one-x $cdr-space-quarter-x;
    align-self: start;

    @include cdr-sm-mq-up {
      padding: $cdr-space-two-x $cdr-space-two-x $cdr-space-half-x;
    }

    :deep(.ls-content-block__actions-container) {
      flex-wrap: wrap;
      gap: 0.8rem;
    }
    :deep(.ls-content-block__actions-container a) {
      flex-shrink: 0;
      margin-left: 0;
      text-align: center;
    }

    :deep(.ls-content-block__heading) {
      @include cdr-text-heading-display-800;

      @include cdr-sm-mq-up {
        @include cdr-text-heading-display-900;
      }
    }
  }
}
</style>
/* v8 ignore stop */
