<script>
import {
  CdrGrid,
} from '@rei/cedar';

import LeadCard from './LeadCard.vue';

export default {
  name: 'LeadCards',
  components: {
    CdrGrid,
    LeadCard,
  },
  props: {
    cards: { type: Object, default() { return {}; } },
    leadCardAnalytics: { type: String, default: '', required: true },
  },
  computed: {
    numberOfCards() {
      return Object.keys(this.cards).length;
    },
    isEven() {
      return this.numberOfCards % 2 === 0;
    },
    multipleOfThree() {
      return this.numberOfCards % 3 === 0;
    },
    multipleOfFour() {
      return this.numberOfCards % 4 === 0;
    },
    cardSpacing() {
      return this.multipleOfFour ? 'card-spacing-half-x' : 'card-spacing-three-quarter-x';
    },
    headingSize() {
      if (this.numberOfCards % 4 === 0) return 'mod-four';
      if (this.numberOfCards % 3 === 0) return 'mod-three';
      if (this.numberOfCards % 2 === 0) return 'mod-two';
      return 'default';
    },
    setGridColumns() {
      return {
        'lead-card-wrapper__grid--four-pack': this.multipleOfFour,
        'lead-card-wrapper__grid--three-pack': this.multipleOfThree,
        'lead-card-wrapper__grid--even-pack': this.isEven,
        'lead-card-wrapper__grid--odd-pack': !this.isEven,
      };
    },
  },
};
</script>
<template>
  <div
    class="lead-card-wrapper"
    :data-location-name="leadCardAnalytics"
    data-ui="lead-cards"
  >
    <div class="lead-card-wrapper__inner">
      <cdr-grid
        :class="setGridColumns"
        class="lead-card-wrapper__grid"
      >
        <div
          v-for="(card, index) in cards"
          :key="`card-${index}`"
        >
          <lead-card
            v-bind="card"
            :heading-size="headingSize"
            :card-spacing="cardSpacing"
          />
        </div>
      </cdr-grid>
    </div>
  </div>
</template>
<style lang="scss">
@import '@rei/cdr-tokens/dist/rei-dot-com/scss/cdr-tokens.scss';
.lead-card-wrapper {
  background: $cdr-color-background-secondary;
  z-index: 10;
  position: relative;

  &__inner {
    @include cdr-container;
    top: -32px;
    position: relative;

    @include cdr-sm-mq-up {
      top: -64px;
    }
  }

  &__grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));

    &--odd-pack {
      :first-child {
        @include cdr-xs-mq-only {
          grid-column: span 2;
        }
      }
    }

    &--three-pack {
      @include cdr-sm-mq-up {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
    }

    &--four-pack {
      @include cdr-md-mq-up {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }
    }

  }
}
</style>
