<script>
import LandingImageComponent from '@rei/landing-image-component';
import { CdrButton, CdrLink } from '@rei/cedar';

export default {
  name: 'Story',
  components: {
    LandingImageComponent,
    CdrButton,
    CdrLink,
  },
  props: {
    text: {
      type: Object,
      default() {
        return null;
      },
    },
    media: {
      type: Object,
      default() {
        return null;
      },
    },
    actions: {
      type: Object,
      default() {
        return null;
      },
    },
    storyName: { type: String, default: 'undefined' },
    theme: { type: String, default: null },
    imageAnchor: { type: String, default: null },
    index: { type: Number, default: null },
  },
  computed: {
    hasText() {
      return this.text && this.text.heading && Object.keys(this.text.heading).length > 0;
    },
    hasActions() {
      return this.actions && this.actions.cta && Object.keys(this.actions.cta).length > 0;
    },
    hasMedia() {
      return !!this.media?.images;
    },
    renderStory() {
      return this.hasText && this.hasMedia && this.hasActions;
    },
    altText() {
      const { alt } = this.media.image;
      return alt || '';
    },
    standaloneLink() {
      const { 'standalone-link': standaloneLink } = this.actions || {};
      return standaloneLink && standaloneLink.target && standaloneLink;
    },
    standaloneLinkLabel() {
      const { 'standalone-link-label': standaloneLinkLabel } = this.text || {};
      return standaloneLinkLabel ? standaloneLinkLabel.text : null;
    },
    storyTheme() {
      return this.theme ? `story__wrapper--${this.theme}` : '';
    },
    linkText() {
      return `${this.text.heading.text} ${this.actions.cta.text}`;
    },
    cssVars() {
      return {
        '--image-anchor': this.imageAnchor,
      };
    },
  },
};
</script>
<template>
  <div
    v-if="renderStory"
    class="story"
    data-ui="story"
    :style="cssVars"
  >
    <div
      :class="storyTheme"
      class="story__wrapper"
    >
      <a
        :href="actions.cta.target"
        :data-analytics-id="`${storyName} image_${index + 1}`"
        :aria-label="linkText"
        class="story__hotspot"
        aria-hidden="true"
        tabindex="-1"
      />
      <div class="story__content">
        <h3
          class="story__heading"
          v-html="text.heading.text"
        />
        <cdr-button
          class="story__cta"
          :href="actions.cta.target"
          :aria-label="linkText"
          :data-analytics-id="`${storyName}_${actions.cta.text}_${index + 1}`"
          modifier="secondary"
          tag="a"
        >
          {{ actions.cta.text }}
        </cdr-button>
      </div>
      <landing-image-component
        v-if="hasMedia"
        class="story__img"
        v-bind="media"
      />
    </div>
    <div
      v-if="standaloneLink"
      class="story__standalone-link"
      data-ui="standalone-link"
    >
      <p>
        <span
          v-if="standaloneLinkLabel"
          data-ui="standalone-link-label"
        >
          {{ standaloneLinkLabel }}:
        </span>
        <cdr-link
          :href="standaloneLink.target"
          :data-analytics-id="`${storyName}_${standaloneLink.text}_${index + 1}`"
          modifier="standalone"
        >
          {{ standaloneLink.text }}
        </cdr-link>
      </p>
    </div>
  </div>
</template>
<style lang="scss">
@import "@rei/cdr-tokens/dist/rei-dot-com/scss/cdr-tokens.scss";
@import "../../../../../../style/common/mixins.scss";
.story {
  &__wrapper {
    position: relative;
    height: 420px;
    box-shadow: $cdr-prominence-raised;
    transition: box-shadow $cdr-duration-2-x $cdr-timing-function-ease;
    border-radius: 4px;
    overflow: hidden;

    &#{&}--dark {
      .story__heading {
        color: $cdr-color-text-primary;
      }
      &::before {
        content: none;
      }
      &::after {
        content: none;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: block;
      background-color: rgba(0, 0, 0, 0);
      /* cdr-duration-2-x */
      transition: background-color $cdr-duration-2-x $cdr-timing-function-linear;
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: block;
      background: radial-gradient(
              620px 220px ellipse at bottom left,
              rgba(0, 0, 0, 0.4),
              rgba(0, 0, 0, 0.4) 55%,
              rgba(0, 0, 0, 0)
      );
    }
    &:hover,
    &:focus,
    &:active {
      box-shadow: $cdr-prominence-floating;
      transform: translate3d(0, -0.2rem, 0);
      transition: $cdr-duration-2-x;
    }
    &:hover:before,
    &:focus:before,
    &:active:before {
      background: rgba(0, 0, 0, 0.25);
    }
  }

  &__heading {
    @include cdr-text-utility-sans-strong-300;
    color: $cdr-color-text-inverse;
    margin: 0 0 $cdr-space-three-quarter-x;
    max-width: 229px;
  }

  &__hotspot {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: block;
    z-index: 1;
  }

  &__content {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    padding: $cdr-space-inset-one-x-stretch;
  }

  &__img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: var(--image-anchor);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: var(--image-anchor);
    }
  }

  &__standalone-link {
    padding-top: $cdr-space-one-x;

    p {
      @include cdr-text-body-strong-300;
    }
    a {
      font-weight: 400;
    }
  }
}

</style>
