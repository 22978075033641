<script>
import {
  CdrLink,
  CdrCard,
} from '@rei/cedar';
import LandingImageComponent from '@rei/landing-image-component';
import LandingBadge from '@rei/landing-badge';
import palettes from '../../../../common/mixins/palettes';

export default {
  name: 'LeadCard',
  components: {
    LandingImageComponent,
    CdrLink,
    CdrCard,
    LandingBadge,
  },
  mixins: [palettes],
  props: {
    card: {
      type: Object,
      default() {
        return null;
      },
    },
    index: {
      type: Number,
      default: 0,
    },
    cardSpacing: {
      type: String,
      default: 'card-spacing-three-quarter-x',
    },
    headingSize: {
      type: String,
      default: 'card-heading-default',
    },
    modelName: {
      type: String,
      required: false,
      default: '',
    },
    target: {
      type: String,
      required: false,
      default: '',
    },
    heading: {
      type: String,
      required: false,
      default: '',
    },
    media: {
      type: Object,
      required: true,
      default: () => null,
    },
    badge: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    body: {
      type: String,
      required: false,
      default: '',
    },
    category: {
      type: String,
      required: false,
      default: '',
    },
    amountOff: {
      type: String,
      required: false,
      default: '',
    },
    couponCode: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    hasDynamicImage() {
      return this.media?.image?.dynamic;
    },
    dynamicClass() {
      return this.hasDynamicImage ? 'color-multiply' : '';
    },
    imageClass() {
      return this.hasDynamicImage
        ? `color-multiply ${this.cardSpacing}`
        : this.cardSpacing;
    },
    imageRatio() {
      return this.hasDynamicImage ? '4-3' : null;
    },
    prefixText() {
      if (this.couponCode || this.amountOff) {
        return this.couponCode ? ' with code ' : 'up to ';
      }
      return null;
    },
    baseText() {
      if (this.couponCode || this.amountOff) {
        return this.couponCode || `${this.amountOff} off`;
      }
      return null;
    },
    analyticsId() {
      const heading = this.heading || `${this.category}: ${this.prefixText} ${this.baseText}`;
      return `${this.modelName}_${heading}_${this.index}`;
    },
    showSeparator() {
      return !!(!this.couponCode && this.category);
    },
  },
};
</script>
<template>
  <div
    :style="cssContentPaletteVars"
    class="card-container"
  >
    <cdr-card
      :class="[
        cardSpacing,
      ]"
      class="lead-card"
      data-ui="lead-card"
    >
      <div
        :class="imageClass"
        class="lead-card__image-wrapper"
      >
        <landing-image-component
          v-if="media"
          class="image-blend"
          v-bind="media"
        />
        <landing-badge
          v-if="badge && badge.text"
          class="lead-card__badge"
          v-bind="badge"
        />
      </div>
      <div
        class="lead-card__content"
      >
        <!-- Card heading -->
        <cdr-link
          :class="[
            `lead-card__heading--${headingSize}`,
            'lead-card__heading lead-card__link cdr-card__link'
          ]"
          :data-analytics-id="analyticsId"
          :href="target"
          data-ui="card-heading"
        >
          <!-- Text for category -->
          <span
            v-if="category"
            class="lead-card__heading--category"
            :aria-label="category"
          >
            {{ category }}
          </span>

          <!-- Separator after category if control -->
          <span
            v-if="showSeparator"
            class="lead-card__heading--separator"
          >:&nbsp;</span>

          <!-- Smaller, prefix text-->
          <span
            v-if="prefixText"
            class="lead-card__heading--prefix"
          >{{ prefixText }}</span>

          <!-- Larger, base text -->
          <span
            v-if="baseText"
            class="lead-card__heading--base"
            v-html="baseText"
          />

          <!-- Continue to allow space for plain headings -->
          <p
            v-if="heading"
            v-html="heading"
          />
        </cdr-link>
        <p
          v-if="body"
          class="lead-card__body"
          v-html="body"
        />
      </div>
    </cdr-card>
  </div>
</template>

<style lang="scss">
@import '@rei/cdr-tokens/dist/rei-dot-com/scss/cdr-tokens.scss';
.card-container {
  height: 100%;
}

.lead-card {
  border: 1px solid #DADADA;
  padding: $cdr-space-half-x;
  height: 100%;

  &__content {
    padding: $cdr-space-inset-half-x;

    @include cdr-sm-mq-up {
      padding-bottom: $cdr-space-one-x;
    }
  }

  &__heading--category {
    display: inline;
  }

  &__heading {
    @include cdr-text-heading-serif-500;
    margin: 0 0 $cdr-space-half-x;
    display: block;

    @include cdr-sm-mq-up {
      &--mod-two,
      &--mod-four {
        @include cdr-text-heading-serif-600;
      }
    }

    @include cdr-md-mq-up {
      &,
      &--three {
        @include cdr-text-heading-serif-600;
      }
    }
  }

  &__body {
    margin: 0;

    @include cdr-sm-mq-up {
      margin-bottom: $cdr-space-half-x;
    }

    a {
      position: relative;
      z-index: 1;
    }
  }

  &__badge {
    position: absolute;
    left: 0;
    bottom: $cdr-space-one-x;
  }

  &__link {
    display: inline;
    text-decoration: none;
    color: var(--palette-heading-color) !important;

    &:hover {
      color: var(--palette-heading-color) !important;
      text-decoration: underline;
    }
  }

  &__image-wrapper {
    margin-bottom: $cdr-space-half-x;
    position: relative;

    .landing-image-component__image {
      width: 100%;
    }

    &.card-spacing-three-quarter-x {
      @include cdr-md-mq-up {
        margin-bottom: $cdr-space-three-quarter-x;
      }
    }
  }

  @supports (mix-blend-mode: darken) {
    &__image-wrapper.color-multiply {
      box-shadow: inset 0 0 100px #DADADA;
      transition: 0.3s;
      padding: $cdr-space-three-quarter-x;
    }

    &:hover &__image-wrapper.color-multiply{
      box-shadow: inset 0 0 100px #EAEAEA;
    }

    &__image-wrapper.color-multiply .image-blend {
      mix-blend-mode: darken;
    }
  }
}
</style>
