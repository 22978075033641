<script>
export default {
  name: 'HeadingSection',
  props: {
    heading: {
      type: String,
      required: false,
      default: '',
    },
  },
};
</script>

<template>
  <h2 class="heading-section">{{ heading }}</h2>
</template>

<style lang="scss">
@import '@rei/cdr-tokens/dist/rei-dot-com/scss/cdr-tokens.scss';
.heading-section {
  @include cdr-text-heading-serif-600;
  display: block;
  width: auto;
  margin: 0 0 $cdr-space-one-and-a-half-x;
  color: $cdr-color-text-primary;

  @include cdr-md-mq-up {
    @include cdr-text-heading-serif-800;
  }
}
</style>
