<script>
import LandingImageComponent from '@rei/landing-image-component';

export default {
  name: 'InfoCard',
  components: {
    LandingImageComponent,
  },
  props: {
    heading: { type: String, required: false, default: '' },
    body: { type: String, required: false, default: '' },
    lockup: { type: Object, required: false, default: () => ({}) },
    link: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
};
</script>
<template>
  <div
    class="info-card"
    :class="{ linkable: link?.href }"
  >
    <a
      v-if="link?.href"
      class="info-card__link"
      :href="link.href"
      :title="link.text"
      :alt="link.text"
    >
      <span class="sr-only">{{ heading }}</span>
    </a>

    <div class="info-card__content">
      <landing-image-component
        class="info-card__lockup"
        v-bind="lockup"
      />
      <h3 class="info-card__heading">{{ heading }}</h3>
      <p
        v-if="body"
        class="info-card__body"
      >
        {{ body }}
      </p>
    </div>
  </div>
</template>

<style lang="scss">
@import '@rei/cdr-tokens/dist/rei-dot-com/scss/cdr-tokens.scss';
@import '../../../../style/common/mixins.scss';

.info-card {
  position: relative;
  min-width: 280px;
  flex-basis: 368px;
  width: 25%;
  height: auto;
  flex-grow: 2;
  border-radius: 8px;
  background-color: #fff;

  @include cdr-md-mq-down{
    flex-basis: 280px;
  }

  &.linkable {
    border: 2px solid $cdr-color-border-primary;

    &:hover, &:active, &:focus, &:focus-within {
      background-color: $cdr-color-background-secondary;
    }
    a, a:hover {
      text-decoration: none !important;
    }
  }

  &__link {
    @include link-tile-cover;
    span {
      @include cdr-display-sr-only;
    }
  }

  &__content {
    display: block;
    width: 100%;
    height: 100%;
    padding: $cdr-space-one-x $cdr-space-one-and-a-half-x;
  }

  &__lockup {
    display: block;
    margin: 0;
  }

  &__heading {
    @include cdr-text-heading-sans-400;
    margin: $cdr-space-half-x 0 0;

    @include cdr-md-mq-up {
      @include cdr-text-heading-sans-600;
      margin: $cdr-space-three-quarter-x 0 0;
    }
  }

  &__body {
    @include cdr-text-body-300;
    margin: $cdr-space-half-x 0 0;
  }
}
</style>
