/* v8 ignore start */
<script>
import { CdrButton, IconArrowLeft, IconArrowRight } from '@rei/cedar';
import LeadRailCard from './LeadRailCard.vue';

import LeadRailTestData from './LeadRailTestData.json';

/** @type ResizeObserver */
let resizeObserver = null;
/** @type IntersectionObserver */
let intersectionObserver = null;

export default {
  name: 'LeadRail',
  components: {
    CdrButton,
    IconArrowLeft,
    IconArrowRight,
    LeadRailCard,
  },
  props: {
    media: { type: Object, required: false, default: () => {} },
    content: { type: Object, required: false, default: () => {} },
    loopOnEnd: { type: Boolean, required: false, default: true },
  },
  data() {
    return {
      isLoaded: false,
      slideIndex: 0,
      dotIndex: 0,
      dotCount: 5,
      dotsVisible: false,
      // Rail stuff
      wrapperWidth: 0,
      cardWidth: 0,
      // Arrows
      enableLeft: false,
      enableRight: true,
    };
  },
  computed: {
    slides() {
      return LeadRailTestData || [];
    },
    scrollWidth() {
      let count = Math.min(Math.floor(this.wrapperWidth / this.cardWidth), 2);
      if (count > 1) count -= 0.5;
      return this.cardWidth * count;
    },
    dotCountArr() {
      return Array(this.dotCount);
    },
  },
  watch: {
    dotsVisible(newVal) {
      if (newVal) {
        this.slideIndex = 0;
        this.dotIndex = 0;
        // Also resize dots
        this.computeDotCount();
        this.computeDotLocation();
        this.$refs.scrollWrapper.scrollLeft = 0;
      }
    },
  },
  mounted() {
    // Setup resize observer
    resizeObserver = new ResizeObserver(this.railResized);
    resizeObserver.observe(this.$refs.wrapper);
    // Setup observer
    const options = {
      root: this.$refs.scrollWrapper,
      threshold: 0.95,
    };
    intersectionObserver = new IntersectionObserver(this.slideVisible, options);
    this.$refs.slides.forEach((s) => intersectionObserver.observe(s.$el));
    // Setup data
    this.wrapperWidth = this.$refs.wrapper.offsetWidth;
    this.cardWidth = this.$refs.slides[0].$el.offsetWidth;
    this.computeDotCount();
    this.isLoaded = true;
  },
  unmounted() {
    resizeObserver.disconnect();
  },
  methods: {
    // Observer methods
    railResized(entries) {
      entries.every((entry) => {
        if (entry.contentBoxSize) {
          this.wrapperWidth = entry.contentBoxSize[0].inlineSize;
          this.cardWidth = this.$refs.slides[0].$el.offsetWidth;
          return false;
        }
        return true;
      });
      this.dotsVisible = this.$refs.dots.offsetWidth > 0;
      this.computeDotCount();
      this.computeDotLocation();
    },
    slideVisible(entries) {
      entries.forEach((entry) => {
        const isFirst = entry.target.dataset.isFirst === 'true';
        const isLast = entry.target.dataset.isLast === 'true';
        if (isFirst) {
          this.enableLeft = !entry.isIntersecting;
        } else if (isLast) {
          this.enableRight = !entry.isIntersecting;
        }
        // Just a generic slide
        if (entry.isIntersecting) {
          const { index } = entry.target.dataset;
          this.slideIndex = parseInt(index, 10);
          this.computeDotLocation();
        }
      });
    },
    // General methods
    computeDotCount() {
      const numCards = Math.floor(this.wrapperWidth / this.cardWidth) || 1;
      this.dotCount = Math.ceil(this.slides.length / numCards);
    },
    computeDotLocation() {
      if (this.$refs.scrollWrapper.scrollLeft < this.cardWidth / 2) this.dotIndex = 0;
      else this.dotIndex = Math.round((this.slideIndex * this.dotCount) / this.slides.length);
    },
    dotClick(i) {
      if (this.dotIndex === i) return;
      const pos = this.dotCount < this.slides.length ? this.wrapperWidth : this.cardWidth;
      const scrollLeft = Math.max(pos * i - (pos / 2), 0);
      this.$refs.scrollWrapper.scrollTo({
        left: scrollLeft,
        behavior: 'smooth',
      });
      window.metrics?.click({
        linkName: `ct_1_${i < this.dotIndex ? 'previous slide' : 'next slide'}`,
      });
    },
    arrowScroll(direction = 1) {
      const pos = this.$refs.scrollWrapper.scrollLeft;
      this.$refs.scrollWrapper.scrollTo({
        left: direction * this.scrollWidth + pos,
        behavior: 'smooth',
      });
      window.metrics?.click({
        linkName: `ct_1_${direction < 0 ? 'previous slide' : 'next slide'}`,
      });
    },
  },
};
</script>
<template>
  <div
    class="lead-rail"
    data-ui="lead-rail"
    data-analytics-config="exclude-all"
  >
    <!-- eslint-disable vue/require-v-for-key -->
    <!-- eslint-disable vue/no-v-for-template-key -->
    <div
      ref="wrapper"
      class="lead-rail__wrapper"
      :class="{ loading: !isLoaded }"
    >
      <div class="lead-rail__controls">
        <div class="lead-rail__controls-wrapper">
          <div class="scroll-btn scroll-left">
            <cdr-button
              icon-only
              with-background
              aria-label="Scroll to previous slide"
              :disabled="!enableLeft"
              @click.prevent.stop="arrowScroll(-1)"
            >
              <icon-arrow-left />
            </cdr-button>
          </div>
          <div class="control-height" />
          <div class="scroll-btn scroll-right">
            <cdr-button
              icon-only
              with-background
              aria-label="Scroll to next slide"
              :disabled="!enableRight"
              @click.prevent.stop="arrowScroll(1)"
            >
              <icon-arrow-right />
            </cdr-button>
          </div>
        </div>
      </div>
      <div
        ref="scrollWrapper"
        class="lead-rail__slide-wrapper"
        :class="{ 'large-screen': !enableRight && !enableLeft }"
        aria-live="polite"
      >
        <div class="buffer" />
        <lead-rail-card
          v-for="(slide, index) in slides"
          v-bind="slide"
          ref="slides"
          :key="`slide-${index}`"
          :data-is-first="index === 0"
          :data-is-last="index === slides.length - 1"
          :data-index="index"
        />
        <div class="buffer" />
      </div>
      <div
        ref="dots"
        class="lead-rail__dot-wrapper"
      >
        <template
          v-for="(e, index) in dotCountArr"
          :key="`dot-${index}`"
        >
          <button
            class="lead-rail__dot"
            :class="{ 'active': dotIndex === (index) }"
            :aria-label="`Click to load slide ${index + 1}`"
            @click.prevent.stop="dotClick(index)"
          />
        </template>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import '@rei/cdr-tokens/dist/rei-dot-com/scss/cdr-tokens.scss';

.lead-rail__wrapper {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  background: #fff;
  max-height: 854px;

  &.loading {
    opacity: 0;
  }

  .lead-rail__controls {
    display: none;
    grid-template-columns: 100%;
    grid-template-rows: 1fr auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    margin: 0 auto;
    max-width: 1800px;
    pointer-events: none;
    z-index: 1;

    @include cdr-sm-mq-up {
      display: grid;
    }
  }

  .lead-rail__controls-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .control-height {
      display: block;
      width: 100%;
      height: auto;
      max-width: 315px;
      min-width: 315px;
      pointer-events: none;
      aspect-ratio: 315/226;
      flex-shrink: 0 !important;
      flex-basis: 100%;
      @include cdr-sm-mq-up {
        max-width: 619px;
        aspect-ratio: 619/443;
      }
    }

    .scroll-btn {
      display: flex;
      position: absolute;
      left: 0;
      width: 4rem;
      height: 100%;
      margin: auto 8px;
      align-items: center;
      text-align: center;
      pointer-events: all;

      @include cdr-sm-mq-up {
        margin: auto 40px;
      }

      &.scroll-right {
        left: auto;
        right: 0;
      }

      button {
        width: 4rem;
        height: 4rem;
        transition: opacity 0.3s;
        &:disabled {
          opacity: 0;
        }
      }
    }
  }

  .lead-rail__dot-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    text-align: center;
    width: 100%;
    margin: 8px auto;
    z-index: 1;

    @include cdr-sm-mq-up {
      display: none;
    }

    .lead-rail__dot {
      width: 16px;
      height: 16px;
      background-color: #fff;
      border: 0.5px solid $cdr-color-border-secondary;
      border-radius: 20px;
      cursor: pointer;
      padding: 0;
      margin: 0;

      &.active {
        background-color: $cdr-color-icon-emphasis;
      }
    }
  }

  .lead-rail__slide-wrapper {
    position: relative;
    display: flex;
    align-items: stretch;
    width: 100%;
    height: 100%;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scroll-snap-stop: normal;
    // Scrollbar logic
    scrollbar-width: none;

    &.large-screen {
      justify-content: center;
    }

    @include cdr-sm-mq-up {
      scroll-snap-stop: normal;
      scroll-snap-type: x mandatory;
      padding: 0 32px;

      .buffer {
        display: block;
      }
    }

    &::-webkit-scrollbar {
      width: 0;
    }

    .buffer {
      display: none;
    }
  }
}
</style>
/* v8 ignore stop */
