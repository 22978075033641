import { getUser } from '@rei/session';

export default {
  data() {
    return {
      loading: true,
      user: null,
    };
  },
  mounted() {
    this.getUserAccountData();
  },
  methods: {
    async getUserAccountData() {
      try {
        const response = await getUser();
        this.user = this.populateUserInformation(response);
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    populateUserInformation(response) {
      let user = {};
      if (response) {
        if (response.contact) {
          user = {
            ...user,
            firstName: response.contact.firstName,
            lastName: response.contact.lastName,
          };
        }
        if (response?.membership?.memberNumber) {
          user = {
            ...user,
            ...response.membership,
            isMember: true,
          };
        }
        user = {
          ...user,
          isLoggedIn: response.isLoggedIn,
        };
      }
      return user;
    },
  },
};
