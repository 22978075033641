<script>
import {
  CdrGrid,
} from '@rei/cedar';
import headingSection from '../headingSection/headingSection.vue';
import Story from './components/Story.vue';

export default {
  name: 'SecondaryStories',
  components: {
    CdrGrid,
    Story,
    headingSection,
  },
  props: {
    secondaryStories: { type: Object, default() { return null; } },
  },
  computed: {
    componentHeading() {
      return this.secondaryStories.text.heading.text
        ? this.secondaryStories.text.heading.text
        : 'Featured collections';
    },
    stories() {
      const { children } = this.secondaryStories || {};
      return children || null;
    },
    storyKeys() {
      return this.stories ? Object.keys(this.stories).length : null;
    },
    setGridColumns() {
      if (this.storyKeys === 3) {
        return 'secondary-stories__grid--three-pack';
      }
      return 'secondary-stories__grid--default';
    },
  },
};
</script>
<template>
  <div
    v-if="stories"
    class="secondary-stories"
    data-ui="secondary-stories"
  >
    <heading-section :heading="componentHeading" />
    <cdr-grid
      :class="setGridColumns"
      class="secondary-stories__grid"
    >
      <div
        v-for="(story, item, index) in stories"
        :key="`story-${index}`"
      >
        <story
          :text="story.text"
          :actions="story.actions"
          :media="story.media"
          :story-name="story.modelName"
          :index="index"
          :theme="story?.config?.theme"
          :image-anchor="story?.config?.imageAnchor"
        />
      </div>
    </cdr-grid>
  </div>
</template>
<style lang="scss">
@import '@rei/cdr-tokens/dist/rei-dot-com/scss/cdr-tokens.scss';
.secondary-stories {
  @include cdr-container;

  &__grid {
    &--default {
      grid-template-columns: repeat(2, 1fr);
      @include cdr-xs-mq-only {
        > div {
          grid-column: span 2;
        }
      }
    }
    &--three-pack {
      grid-template-columns: repeat(3, 1fr);
      @include cdr-sm-mq-only {
        grid-template-columns: repeat(2, 1fr);
        :nth-child(1) {
          grid-column: span 2;
        }
      }
      @include cdr-xs-mq-only {
        grid-template-columns: 1fr;
      }
    }
  }

  &__heading {
    margin-bottom: $cdr-space-one-and-a-half-x;

    @include cdr-sm-mq-up {
      margin-bottom: $cdr-space-two-x;
    }
  }
}
</style>
