/* v8 ignore start */
<script>
import LandingImageComponent from '@rei/landing-image-component';
import { LandingContentBlock } from '@rei/landing-content-block';

export default {
  name: 'CompactMemberZone',
  components: {
    LandingContentBlock,
    LandingImageComponent,
  },
  props: {
    testData: { type: Object, required: true, default: () => ({}) },
  },
  data() {
    return {
      media: this.testData.media,
      content: this.testData.content,
      info: this.testData.info,
    };
  },
};
</script>
<template>
  <div
    class="compact-mz"
    data-ui="compact-mz"
  >
    <section class="compact-mz__container">
      <div class="compact-mz__header">
        <landing-content-block
          v-if="content"
          data-ui="compact-mz__content"
          v-bind="content"
        />
      </div>
      <div class="compact-mz__info">
        <div
          ref="scrollWrapper"
          class="compact-mz__slide-wrapper"
        >
          <div class="buffer" />
          <div class="compact-mz__slide-container">
            <div class="info-title">{{ info.title }}</div>
            <div
              v-for="(slide, i) in info.benefits"
              :key="`slide-${i}`"
              class="info-slide"
            >
              <div class="info-slide__title">{{ slide.title }}</div>
              <div class="info-slide__body">{{ slide.body }}</div>
            </div>
          </div>
          <div class="buffer" />
        </div>
      </div>
    </section>
    <landing-image-component
      class="compact-mz__background"
      v-bind="media"
      :lazy-load="false"
      fetch-priority="high"
      object-fit="cover"
      :use-resizing="false"
    />
  </div>
</template>
<style lang="scss" scoped>
@import '@rei/cdr-tokens/dist/rei-dot-com/scss/cdr-tokens.scss';

.compact-mz {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  color: $cdr-color-text-inverse;
  font-family: $cdr-font-family-sans;
  overflow-x: visible;
  overflow-y: hidden;
  padding-bottom: 32px;

  @include cdr-sm-mq-up {
    padding: 24px 0 32px;
  }

  @include cdr-md-mq-up {
    padding: 48px 0;
  }

  &__background {
    :deep(.landing-image-component__image) {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      min-width: 100%;
      height: 103%;
    }
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-grow: 0;
    width: 100%;
    max-width: 832px;
  }

  &__header, &__slide-container {
    background-color: $cdr-color-text-brand;
  }

  &__header {
    max-width: 628px;
    width: 100%;
    margin: 24px 24px 16px;
    padding: 24px 24px 0;

    @include cdr-sm-mq-up {
      margin: 0 0 16px;
      max-width: 497px;
    }
    @include cdr-md-mq-up {
      max-width: 632px;
      margin-bottom: 32px;
    }

    :deep(.ls-content-block__heading) {
      font-family: $cdr-font-family-sans;
      text-transform: uppercase;
      font-weight: 600;
      @include cdr-md-mq-up {
        font-size: 42px;
      }
    }
  }

  &__info {
    width: 100%;
    max-width: 676px;
    // margin: 16px 0 32px 8px;
    height: auto;

    @include cdr-sm-mq-up {
      max-width: none;
      display: flex;
      justify-content: center;
    }
  }
  &__slide-wrapper {
    display: flex;
    width: 100%;
    overflow-x: scroll;
    // Scrollbar logic
    scrollbar-width: none;

    @include cdr-sm-mq-up {
      overflow-x: visible;
      justify-content: center;
    }
  }

  &__slide-container {
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
    min-height: 112px;
    width: auto;
    height: auto;
    padding: $cdr-space-three-quarter-x $cdr-space-one-and-a-half-x;
    align-items: center;
    align-content: stretch;

    @include cdr-md-mq-up {
      justify-content: center;
      width: 100%;
      padding: $cdr-space-inset-two-x-squish;
    }
  }

  .buffer {
    width: 24px;
    flex-shrink: 0;
    scroll-snap-align: start;
    @include cdr-sm-mq-up {
      display: none;
    }
  }

  .info-title {
    display: flex;
    width: 85px;
    height: 72px;
    flex-direction: column;
    justify-content: center;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 90%;
    font-style: normal;
    letter-spacing: -0.6px;

    @include cdr-md-mq-up {
      width: 99px;
      height: auto;
      font-size: 24px;
    }
  }

  .info-slide {
    box-sizing: content-box;
    display: flex;
    width: 149px;
    flex-wrap: wrap;
    padding: 16px 8px;
    margin-left: 16px;
    align-self: flex-start;
    flex-shrink: 0;

    @include cdr-sm-mq-up {
      max-height: 112px;
    }

    @include cdr-md-mq-up {
      max-height: none;
      margin-left: 32px;
      padding: 0;
      flex: 1 0 0;
    }

    &__title {
      @include cdr-text-subheading-sans-300;
      margin-bottom: 8px;
      @include cdr-md-mq-up {
        @include cdr-text-subheading-sans-400;
      }
    }
    &__body {
      @include cdr-text-utility-sans-100;
      @include cdr-md-mq-up {
        @include cdr-text-utility-sans-200;
      }
    }
  }
}
</style>
/* v8 ignore stop */
