const setCouponActions = (accomulateActions, currentCategory, index) => {
  const newAccomulateActions = accomulateActions;
  newAccomulateActions[`category-${index + 1}`] = {
    index,
    target: currentCategory?.href,
    text: currentCategory?.name,
    media: {
      alt: currentCategory?.name,
      dynamic: true,
      src: currentCategory?.imageURL,
    },
  };
  return newAccomulateActions;
};

const formatRRCategories = (obj = {}) => (
  obj?.recsItems?.slice(0, 10)?.reduce(setCouponActions, {}) || {}
);

export default formatRRCategories;
