<script>

import { CdrLink, CdrText, CdrList } from '@rei/cedar';
import LandingImageComponent from '@rei/landing-image-component';

export default {
  name: 'LinkBlockComponent',
  components: {
    CdrLink,
    CdrText,
    CdrList,
    LandingImageComponent,
  },
  props: {
    blocks: { type: Array, required: true, default: () => [] },
    media: { type: Object, required: true, default: () => {} },
  },
};
</script>

<template>
  <div class="landing-link-block">
    <div class="landing-link-block__links">
      <div
        v-for="(column,i) in blocks"
        :key="i"
        class="landing-link-block__column"
      >
        <cdr-text
          tag="h2"
          class="landing-link-block__column-heading"
        >
          {{ column.heading }}
        </cdr-text>
        <cdr-list>
          <li
            v-for="(link, j) in column.links"
            :key="j"
          >
            <cdr-link
              :href="link.href"
              modifier="standalone"
            >
              {{ link.anchor }}
            </cdr-link>
          </li>
        </cdr-list>
      </div>
    </div>
    <div class="landing-link-block__image-container">
      <landing-image-component
        v-bind="media"
        custom-classes="landing-link-block__image"
      />
    </div>
  </div>
</template>

<style lang="scss">
@import '@rei/cdr-tokens/dist/rei-dot-com/scss/cdr-tokens.scss';

.landing-link-block {
  @include cdr-container;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  @include cdr-sm-mq-up {
    flex-wrap: nowrap;
  }

  &__column {
    padding-right: $cdr-space-half-x;
    width: 50%;
  }

  &__column-heading {
    @include cdr-text-heading-sans-300;
    margin-bottom: $cdr-space-one-x;
  }

  &__links {
    padding: $cdr-space-two-x;
    background-color: $cdr-color-background-secondary;
    width: 100%;
    display: flex;
  }

  &__image-container {
    display: none;

    @include cdr-sm-mq-up {
      display: block;
    }
  }

  &__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    overflow: hidden;
  }

  &__links,
  &__image-container {

    @include cdr-sm-mq-up {
      width: 50%;
    }
  }

}
</style>
