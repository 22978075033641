<script>
import {
  CdrText,
  CdrButton,
  CdrRating,
  IconXLg,
} from '@rei/cedar';
import Cookies from 'js-cookie';

const COOKIE_NAME = 'appBannerHidden';

export default {
  name: 'AppDownloadBanner',
  components: {
    CdrButton,
    CdrRating,
    CdrText,
    IconXLg,
  },
  props: {
    initialState: { type: Boolean, default: false },
  },
  data() {
    return {
      showBanner: !!this.initialState,
    };
  },
  mounted() {
    this.checkBanner();
  },
  methods: {
    closeBanner() {
      Cookies.set(COOKIE_NAME, true, { expires: 30 });
      this.showBanner = false;
    },
    checkBanner() {
      const appBannerHidden = Cookies.get(COOKIE_NAME);
      if (!appBannerHidden) {
        this.showBanner = true;
      }
    },
    handleClose() {
      this.closeBanner();
    },
    handleClick() {
      this.closeBanner();
    },
  },
};
</script>
<template>
  <div
    id="app-download"
    ref="appDownload"
  >
    <div
      v-if="showBanner"
      class="app-download app-download--bottom"
      data-ui="app-download"
    >
      <div class="app-download__grid">
        <div class="app-download__logo">
          <cdr-button
            :icon-only="true"
            :with-background="false"
            class="app-download__close"
            aria-label="Close"
            data-ui="app-download-close"
            data-analytics-id="rei:close app banner"
            @click="handleClose"
          >
            <template #icon>
              <icon-x-lg />
            </template>
          </cdr-button>
          <div
            class="rei_logo logo"
            data-ui="app-download-logo"
          >
            <svg
              class="logo__img"
              xmlns="http://www.w3.org/2000/svg"
              width="158"
              height="100"
              viewBox="0 0 158 100"
            >
              <title>REI Co-op</title>
              <g fill-rule="evenodd">
                <!-- eslint-disable-next-line max-len -->
                <path d="M128.3 80.3H116A4.6 4.6 0 0 0 111 85v4.7a4.6 4.6 0 0 0 4.7 4.6h12.3a4.6 4.6 0 0 0 4.6-4.6V85a4.6 4.6 0 0 0-4.7-4.7zm.4 9.4a.4.4 0 0 1-.4.4H116a.4.4 0 0 1-.6-.3V85c0-.3.2-.4.5-.4h12.3c.2 0 .4 0 .4.4v4.7zm-21-5c-.2-.2-.4-.2-.5 0l-1.4 2.3a.6.6 0 0 0 0 .6l1.4 2.4h.4l1.4-2.4a.6.6 0 0 0 0-.6l-1.4-2.3m45-4.4h-17.2V100h4.2v-5.7h13a4.6 4.6 0 0 0 4.5-4.6V85a4.6 4.6 0 0 0-4.5-4.7zm.3 9.4a.4.4 0 0 1-.5.4h-13v-5.4h13c.2 0 .4 0 .4.4v4.7zM142.3 76h11.8a3 3 0 0 0 3-2.8V37.7h-14.8V76m-83 9v4.7a4.6 4.6 0 0 0 4.7 4.6h15.3V90H64a.4.4 0 0 1-.3-.3V85c0-.3 0-.4.4-.4h15.3v-4.3H64a4.6 4.6 0 0 0-4.6 4.7m49-9h28v-8H120v-7h16.4v-8.2H120v-7h16.4v-8h-31V73a3 3 0 0 0 3 3" />
                <!-- eslint-disable-next-line max-len -->
                <path d="M59.4 49.7V76h14.8V66h4.5l7 10H102l-8.4-12.4 5.8-5.8v-20H84.7L74.2 43v-3.8c0-.8-.7-1.5-1.6-1.5h-4L91.5 7 119 31.5l14-15.3 18 15.3h6L133 10.3 117 23.6 93.4 3l-3-2.8L41 49.4l-5.7-11.7H40L25.5 10.3 11.3 37.7h4.5L6.2 57h6L1 80.2l53.2 14-10.5-23.7 15.7-21zm14.8 1.7L84.7 46v11H74.2v-5.6z" />
                <!-- eslint-disable-next-line max-len -->
                <path d="M99 80.3H86.4A4.6 4.6 0 0 0 82 85v4.7a4.6 4.6 0 0 0 4.5 4.6H99a4.6 4.6 0 0 0 4.5-4.6V85a4.6 4.6 0 0 0-4.6-4.7zm.3 9.4a.4.4 0 0 1-.4.4H86.4a.4.4 0 0 1-.4-.3V85c0-.3.3-.4.5-.4H99s.3 0 .3.4v4.7z" />
              </g>
            </svg>
          </div>
        </div>
        <div class="app-download__cta">
          <div class="app-download__info">
            <cdr-text
              class="app-download__text"
              data-ui="app-download-text"
            >
              A faster, easier REI<span />
            </cdr-text>
            <cdr-rating
              size="small"
              rating="4.5"
              data-ui="app-download-rating"
            />
          </div>
          <cdr-button
            class="app-download__button"
            tag="a"
            href="https://app.rei.com/rei/app-download/homepage/bottom-banner-stars"
            data-ui="app-download-cta"
            @click="handleClick"
          >
            Get the app
          </cdr-button>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import "@rei/cdr-tokens/dist/rei-dot-com/scss/cdr-tokens.scss";
.app-download {
  background: $cdr-color-background-primary;
  border: 1px solid $cdr-color-border-primary;
  box-shadow: $cdr-prominence-lifted;
  left: 0;
  padding: $cdr-space-one-x 0;
  width: 100%;
  z-index: 9999;

  @include cdr-sm-mq-up {
    display: none;
  }

  &--bottom {
    bottom: 0;
    position: fixed;
  }

  &__cta {
    display: flex;
    justify-content: space-between;
    margin-left: 16px;
    width: -webkit-fill-available;
  }

  &__logo {
    display: flex;
  }

  &__close {
    height: fit-content;
    padding: 12px;
  }

  &__grid {
    display: flex;
    justify-content: space-between;
  }

  .logo {
    background: black;
    border-radius: .7rem;
    height: 56px;
    margin: 0 0 0 8px;
    width: 56px;

    &__img {
      fill: white;
      height: auto;
      margin-top: 8px;
      padding: 3px;
      width: 50px;
    }
  }

  &__text {
    @include cdr-text-utility-sans-500;
    line-height: 2.2rem;
    margin: 5px 0;

    @media screen and (min-width: 431px) {
      margin-top: 12px;
    }
  }

  &__button {
    height: fit-content;
    margin: 10px 18px 0 0;
    white-space: nowrap;
  }

  &__grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
</style>
